import Heading from 'components/Basic/Heading/Heading';
import Breadcrumbs from 'components/Layout/Breadcrumbs/Breadcrumbs';
import Webline from 'components/Layout/Webline/Webline';
import { BreadcrumbItemType } from 'types/breadcrumb';
import twMerge from 'utils/twMerge';

type SimpleLayoutProps = {
    heading: string;
    breadcrumb: BreadcrumbItemType[];
    isStandardWidth?: true;
    innerClassName?: string;
};

const SimpleLayout: FC<SimpleLayoutProps> = ({
    breadcrumb,
    className,
    heading,
    children,
    isStandardWidth,
    innerClassName,
}) => {
    return (
        <>
            <Webline>
                <Breadcrumbs key="breadcrumb" breadcrumb={breadcrumb} />
                <div className="mx-auto mt-0 mb-1 lg:w-desktop">
                    <Heading type="h1">{heading}</Heading>
                </div>
            </Webline>
            <Webline>
                {isStandardWidth !== true && (
                    <div className={twMerge('mb-24 flex w-full justify-center', className)}>
                        <div
                            className={twMerge(
                                'lg:pt-15 lg:px-15 w-full rounded-md bg-grayLight p-5 lg:mt-1 lg:w-desktop lg:pb-11',
                                innerClassName,
                            )}
                        >
                            {children}
                        </div>
                    </div>
                )}
                {isStandardWidth && <>{children}</>}
            </Webline>
        </>
    );
};

export default SimpleLayout;
