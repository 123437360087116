import { useLoginForm, useLoginFormMeta } from './formMeta';
import { ButtonWrapperStyled, LoginLostPassLinkStyled } from './Login.style';
import { LoginSocial } from 'components/Blocks/LoginForm/LoginSocial';
import Button from 'components/Forms/Button/Button';
import Form from 'components/Forms/Form/Form';
import FormLine from 'components/Forms/Lib/FormLine/FormLine';
import FormLineError from 'components/Forms/Lib/FormLineError/FormLineError';
import TextInput from 'components/Forms/TextInput/TextInput';
import SimpleLayout from 'components/Layout/SimpleLayout/SimpleLayout';
import { useAuth } from 'hooks/auth/useAuth';
import { useHandleFormErrors } from 'hooks/forms/UseHandleFormErrors';
import { useHandleFormSuccessfulSubmit } from 'hooks/forms/UseHandleFormSuccessfulSubmit';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/router';
import { Controller, FormProvider, SubmitHandler } from 'react-hook-form';
import { usePersistStore } from 'store/usePersistStore';
import { BreadcrumbItemType } from 'types/breadcrumb';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

type LoginProps = {
    breadcrumbs: BreadcrumbItemType[];
};

const TEST_IDENTIFIER = 'pages-login';

const Login: FC<LoginProps> = ({ breadcrumbs }) => {
    const t = useTypedTranslationFunction();
    const { cartUuid } = usePersistStore((s) => s);
    const { url } = useDomainConfig();
    const { query } = useRouter();
    const [[loginResult, login]] = useAuth();
    const [resetPasswordUrl] = getInternationalizedStaticUrls(['/reset-password'], url);
    const [formProviderMethods, defaultValues] = useLoginForm();
    const {
        fields: { email: emailField, password: passwordField },
        formName,
    } = useLoginFormMeta(formProviderMethods);

    useHandleFormErrors(loginResult.error, formProviderMethods, 'other');
    useHandleFormSuccessfulSubmit(loginResult, formProviderMethods, defaultValues);

    const onLoginHandler: SubmitHandler<{ email: string; password: string }> = async (data) => {
        let redirectUrl = url;

        if (typeof query.r === 'string') {
            redirectUrl = query.r;
        }

        await login(
            { email: data.email, password: data.password, previousCartUuid: cartUuid, deleteOldCustomerCart: false },
            redirectUrl,
        );
    };

    return (
        <SimpleLayout heading={t('Login')} breadcrumb={breadcrumbs}>
            <FormProvider {...formProviderMethods}>
                <Form onSubmit={formProviderMethods.handleSubmit(onLoginHandler)} data-testid={TEST_IDENTIFIER}>
                    <Controller
                        name={emailField.name}
                        render={({ fieldState: { isTouched, invalid, error }, field }) => (
                            <>
                                <FormLine bottomGap>
                                    <TextInput
                                        id={`${formName}-${emailField.name}`}
                                        name={emailField.name}
                                        label={emailField.label}
                                        required
                                        type="text"
                                        isTouched={isTouched}
                                        hasError={invalid}
                                        fieldRef={field}
                                    />
                                    <FormLineError
                                        textInputSize="small"
                                        error={error}
                                        inputType="text-input"
                                        data-testid={`${formName}-${emailField.name}-error`}
                                    />
                                </FormLine>
                            </>
                        )}
                    />
                    <Controller
                        name={passwordField.name}
                        render={({ fieldState: { isTouched, invalid, error }, field }) => (
                            <>
                                <FormLine>
                                    <TextInput
                                        id={`${formName}-${passwordField.name}`}
                                        name={passwordField.name}
                                        label={passwordField.label}
                                        required
                                        type="password"
                                        isTouched={isTouched}
                                        hasError={invalid}
                                        fieldRef={field}
                                    />
                                    <FormLineError
                                        textInputSize="small"
                                        error={error}
                                        inputType="text-input-password"
                                        data-testid={`${formName}-${passwordField.name}-error`}
                                    />
                                </FormLine>
                            </>
                        )}
                    />
                    <LoginLostPassLinkStyled href={resetPasswordUrl} type="static">
                        {t('Lost your password?')}
                    </LoginLostPassLinkStyled>

                    <ButtonWrapperStyled>
                        <Button type="submit" data-testid={`${TEST_IDENTIFIER}-submit`}>
                            {t('Log in')}
                        </Button>
                    </ButtonWrapperStyled>

                    <LoginSocial cartUuid={cartUuid} shouldOverwriteCustomerUserCart itemsInLine />
                </Form>
            </FormProvider>
        </SimpleLayout>
    );
};

export default Login;
