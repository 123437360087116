import SeoMeta from 'components/Basic/Head/SeoMeta';
import Login from 'components/Pages/Login/Login';
import checkIsLoggedOnSSR from 'helpers/checkIsLoggedOnSSR';
import { createClient } from 'helpers/createClient';
import { initServerSideProps, ServerSidePropsType } from 'helpers/InitServerSideProps';
import { getServerSidePropsWrapper } from 'helpers/serverSide/getServerSidePropsWrapper';
import { useGtmStaticPageView } from 'hooks/gtm/useGtmStaticPageView';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useMemo } from 'react';
import { NextPageExtended } from 'types/global';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';
import { useGtmStaticPageViewEvent } from 'utils/Gtm/EventFactories';

const LoginPage: NextPageExtended<ServerSidePropsType> = () => {
    const t = useTypedTranslationFunction();
    const { url: domainUrl } = useDomainConfig();
    const [loginUrl] = getInternationalizedStaticUrls(['/login'], domainUrl);
    const breadcrumbs = useMemo(() => [{ name: t('Login'), slug: loginUrl }], [loginUrl, t]);
    const gtmStaticPageViewEvent = useGtmStaticPageViewEvent('other', breadcrumbs);
    useGtmStaticPageView(gtmStaticPageViewEvent);

    return (
        <>
            <SeoMeta title={t('Login')} />
            <Login breadcrumbs={breadcrumbs} />
        </>
    );
};

export const getServerSideProps = getServerSidePropsWrapper(
    ({ domainConfig, redisClient, ssrExchange, t }) =>
        async (context) => {
            const client = createClient({
                publicGraphqlEndpoint: domainConfig.publicGraphqlEndpoint,
                ssrExchange,
                t,
                context,
                redisClient,
            });

            const serverSideProps = await initServerSideProps({ client, context, domainConfig, ssrExchange });

            const isLogged = checkIsLoggedOnSSR(client);

            if (isLogged) {
                let redirectUrl = '/';
                if (typeof context.query.r === 'string') {
                    redirectUrl = context.query.r;
                }

                return {
                    redirect: {
                        statusCode: 302,
                        destination: redirectUrl,
                    },
                };
            }

            return serverSideProps;
        },
);

export default LoginPage;
