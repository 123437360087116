import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import styled, { css } from 'styled-components';

export const ButtonWrapperStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export const LoginLostPassLinkStyled = styled(ExtendedNextLink)(
    ({ theme }) => css`
        display: block;
        width: fit-content;
        margin-top: 16px;
        margin-left: auto;
        line-height: 16px;

        text-decoration: underline;
        font-size: 14px;
        word-break: break-word;
        color: ${theme.color.primary};

        &:hover {
            text-decoration: none;
            color: ${theme.color.grayDarker};
        }
    `,
);
